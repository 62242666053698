import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  OutboundLink,
  Content,
  CallToActionBanner,
  InternalLink,
  Call,
  Background,
  ContinuousScroll,
  PatternedImageRow,
  BasicCarousel,
  componentIterator,
  Review,
} from "@bluefin/components";
import { Embed, Button, Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import "../style/index.css";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"home-page"}>
          <div className={"hero-images-container"}>
            <div className={"video-container"}>
              <Embed
                url={"https://player.vimeo.com/video/725745127?background=1"}
                autoplay={true}
                defaultActive={true}
                className={"home-hero-video"}
              />
              <div className={"hero-logo-cta-container"}>
                <Image
                  centered={true}
                  size={"medium"}
                  src={fishermanBusiness.logo}
                />
                <Button
                  primary={true}
                  size={"medium"}
                  to={
                    "https://claudios-pizzeria.pdqonlineordering.com/TitlePage.aspx"
                  }
                  as={OutboundLink}
                >
                  Order Online
                </Button>
              </div>
            </div>
          </div>
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"welcome-to-grid"}
          >
            <Grid.Column className={"welcome-copy-column"}>
              <Content stackable={true} textAlign={"left"}>
                <Header
                  as={"h1"}
                  className={"welcome-to-header"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "home_header",
                    defaultValue: "WELCOME",
                  })}
                />
                <Header as={"h2"} className={"welcome-to-subheader"}>
                  DINE WITH US
                </Header>
                <Content.Markup
                  width={16}
                  isMarkdown={true}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "home_description",
                  })}
                />
              </Content>
              <Grid.Column width={16}>
                <CallToActionBanner stackable={true}>
                  <CallToActionBanner.CTA>
                    <Button to={"/menu/"} as={InternalLink}>
                      VIEW MENU
                    </Button>
                  </CallToActionBanner.CTA>
                  <CallToActionBanner.CTA>
                    <Call
                      phoneNumber={
                        fishermanBusiness.primaryLocation.phoneNumber
                      }
                      as={"button"}
                    />
                  </CallToActionBanner.CTA>
                </CallToActionBanner>
              </Grid.Column>
            </Grid.Column>
            <Grid.Column className={"welcome-image-column"}>
              <Image
                className={"image"}
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "Content.Image",
                  componentIdentifier: "welcome",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <div className={"image-gallery-container"}>
            <Background
              columns={1}
              stackable={true}
              className={"images-background"}
            >
              <Background.Pattern type={"CIRCLE"}>
                <ContinuousScroll
                  className={"patterned-images-container-scroll"}
                >
                  <PatternedImageRow
                    containerAs={ContinuousScroll.Item}
                    images={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                    })}
                  />
                </ContinuousScroll>
              </Background.Pattern>
            </Background>
          </div>
          <div className={"reviews-container"}>
            <BasicCarousel
              size={3}
              pageIndicatorIcon={null}
              mobile={{ arrowsPosition: "top" }}
              items={componentIterator({
                iterator: fishermanBusiness.reviews,
                component: <Review as={"horizontalItem"} charLimit={200} />,
                propMap: { author: "author", text: "text" },
              })}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      logo
      primaryLocation {
        phoneNumber
      }
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          _id
        }
        contentNodes {
          content
        }
      }
    }
  }
`;
